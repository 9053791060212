import "./order_status.css";
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from "react-router-dom";

function OrderDetails() {
  const [data, setData] = useState(null);
  const { orderId } = useParams(); // This will give us the orderId from the URL

  useEffect(() => {
    fetch(`https://portal.carddaservices.de/api/order/${orderId}`)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setData(data);
      })
      .catch((error) => console.error(error));
  }, [orderId]); // Include orderId in the dependency array
  if (data?.error) {
    return (
      <div className="container">
        <div>
          <img
            src="https://cdn.shopify.com/s/files/1/0817/1172/6941/files/400x300.png?v=1713213088"
            alt="cardda Logo"
            className=""
            style={{ maxHeight: "100px" }}
          />
        </div>

        <div className="headerline"></div>

        <div>
          <h3 className="color-red">Oops! An Error Occurred</h3>

          <h4>
            Something is broken. Please let us know what you were doing when
            this error occurred. We will fix it as soon as possible. Sorry for
            any inconvenience caused.
          </h4>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div>
        <img
          src="https://cdn.shopify.com/s/files/1/0817/1172/6941/files/400x300.png?v=1713213088"
          alt="cardda Logo"
          className=""
          style={{ maxHeight: "100px" }}
        />
      </div>

      <div className="headerline"></div>

      <div className="">
        <h3>Transaction Status : SUCCESS</h3>
        <h3>Order Number : {data?.data.ordername}</h3>
        <h3>Shipping Address : {data?.data.clientInfo.email}</h3>
        {data?.data.transactionResponses.map(
          ({ productName, pin, serialNumber }, index) => (
            <div key={index}>
              <h4>Product: {productName}</h4>

              <div>
                <table>
                  <tbody>
                    {/* <tr>
                      <td>Produkt</td>
                      <td>{productName}</td>
                    </tr> */}

                    <tr>
                      <td>PIN:</td>
                      {/* <td align="right">{pinCode}</td> */}
                      <td align="right">{pin}</td>
                    </tr>
                    <tr>
                      <td>CODE SERIAL:</td>
                      {/* <td align="right">{pinCode}</td> */}
                      <td align="right">{serialNumber}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
}
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/order/:orderId" element={<OrderDetails />} />
        <Route
          path="/*"
          element={
            <div className="container">
              <div>
                <img
                  src="https://cdn.shopify.com/s/files/1/0817/1172/6941/files/400x300.png?v=1713213088"
                  alt="cardda Logo"
                  className=""
                  style={{ maxHeight: "100px" }}
                />
              </div>

              <div className="headerline"></div>

              <div>
                <h3 className="color-red">Oops! An Error Occurred</h3>

                <h4>
                  Something is broken. Please let us know what you were doing
                  when this error occurred. We will fix it as soon as possible.
                  Sorry for any inconvenience caused.
                </h4>
              </div>
            </div>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
